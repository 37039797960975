* {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  html,
  body {
    top: 0px;
    left: 0px;
    margin: 0 auto;
    height: 100%;
  }
  @media only screen and (max-width: 768px) {
    .hide {
      display: none !important;
    }
  }
  /*..................*/
  
.intro-box .in-txt{
    color:#e54b4b;
    font-size: 80px;
    margin-top: 55px;
}

  #heros {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    box-sizing: border-box;
  }
  
  .slider {
    position: absolute;
    z-index: 3;
    left: 20%;
    top: 11%;
    display: hide;
  }
  @media only screen and (max-width: 1100px) {
    .slider {
      left: 10%;
    }
  }

  @media only screen and (max-width: 1038px) {
    .slider {
      width: 360px;
    }
  }

  @media only screen and (max-width: 950px) {
    .slider {
      width: 300px;
    }
    .intro-box .in-txt{
      font-size: 70px;
    }
  }
  @media only screen and (max-width: 865px) {
    .slider {
      left: 2%;
    }
  }

  @media only screen and (max-width: 768px) {

    #heros{
        flex-direction: row;
    }
   
  }
  /*.......Slider...........*/
  
  .sb-box {
    position: absolute;
    margin-bottom: 0;
    bottom: -4vh;
    height: 14%;
    width: 27%;
    right: 1vw;
    z-index: 999;
    background-color: #e54b4b;
  }
  @media only screen and (max-width: 768px) {
    .sb-box {
      bottom: 4vh;
      right: 14vw;
    }
  }
  
  .hero-carousel {
    position: relative;
  }
  .carousel-item  {
    /* height: 400px !important; */
    max-width: 400px ;
    object-fit: cover;
  }

  .carousel-item img {
    /* height: 400px !important; */
    width: 100%;
  }
  
  
  
  /*.......Sec Two...........*/
  .lrb {
    position: absolute;
    width:2.5vw;
    height:80vh;
     background-color: #e54b4b;
    right:0;
    top:0;
  }
  .lgb {
    position: absolute;
    width:2.5vw;
   background-color: #5d5c69 !important;
    bottom:0;
    right:0;
    height:20vh;
  }
  .red-sec {
    position: absolute;
    height: 100%;
    bottom: 0;
    background-color: #e54b4b;
    width: 40%;
    z-index: 2;
    left: 0;
  }
  .grey-sec {
    position: absolute;
    height: 100%;
    bottom: 0;
    background-color: #f4f4f4;
    width: 60%;
    z-index: 2;
    left: 40%;
  }
  @media only screen and (max-width: 768px) {
    .grey-sec {
      width: 100% !important;
      display: block;
      width: 100%;
      left: 0;
      right: 0;
      height: 100vh;
      bottom: 0;
      top: 0;
    }
  }
  .rbg {
    background-image: url(http://lp3.1a5.myftpupload.com/wp-content/uploads/2019/02/memphis-bg.jpg);
    background-repeat: no-repeat;
    background-position: cover;
    z-index: -1;
  }
  /*.......Intro Text...........*/
  
  .intro-box {
    left: 52%;
    position: absolute;
    font-family: "Poppins", sans-serif;
    text-align: left;
    top: 18%;
    z-index: 5;
    
  }

  @media only screen and (min-width: 1440px) {
    .intro-box {
        left: 50%;
        font-size: 100px;
    }
    .intro-box .in-txt{
      font-size: 100px;
      margin-top: 60px;
    }
    .intro-box .in-b-txt{
      font-size: 20px;
      margin: 60px 0
    }
    .intro-box .in-txt h5{
      margin: 20px 0
    }
    .Vision__button{
      left: 33%;
      top:40%;
      width: 200px;
      height: 200px;
    }
    .Vision__button span {
      
      font-size: 98px;
    }
  }
  /* .intro-box .in-txt{
    color:#e54b4b;
    font-size: 70px;
    margin-top: 40px;
} */


  @media only screen and (max-width: 1044px) {
    .intro-box {
        left: 50%;
    }
  }

  @media only screen and (max-width: 875px) {
    .intro-box {
        left: 43%;
    }
  }

  @media only screen and (max-width: 768px) {
    .intro-box {
      /* top: 70vh; */
      left: 8%;
      width: 80%;
      background-color: #fff;
      bottom: 10%;
      z-index: 3;
 
      padding-left: 30px;
     
      box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);
      border-radius: 10px;
      border: 1px solid rgba(255, 255, 255, 0.18);
   
    }
    

    .intro-box .in-b-txt{
      margin-top: 10px;
      /* margin-bottom: 0; */
    }
    
    .red-sec{
        /* display: none; */
        z-index: -10;
        top:90px;
        width: 70%;
    }
    
    .slider{
        display: none;
    }
    .button-block{
        margin: 120px 30px
    }
    .intro-box .in-txt{
      
        font-size: 45px;
    }
    
    
  }
  .in-txt {
    font-size: 46px;
    letter-spacing: -0.01em;
    font-weight: 800;
    line-height: 3.4rem;
    color: #5d5c69;
    width: 70%;
    margin-top: 4vh;
    /* margin-top: 40px; */
  }

  @media only screen and (max-width: 471px) {
  .button-block{
    margin: 90px 30px
}
.in-txt h5{
  margin-top: -20px;
}

  }

  @media only screen and (max-width: 768px) {
    .in-txt {
      font-size: 26px;
      line-height: 1.5em;
      color: #e54b4b;
      width: 80%;
      /* margin: 5% 10%; */
    }
  }
  .in-title {
    margin-bottom: 2vh;
    font-weight: 600;
    color: #5d5c69;
    font-size: 18px;
    margin-top: 40px;
  }
  .in-title:after {
    position: absolute;
    content: "";
    background: #e54b4b;
    height: 3px;
    width: 10%;
    display: block;
    margin-top: 0.5em;
    opacity: 0.8;
  }
  .in-b-txt {
    margin-bottom: 8vh;
    font-weight: 400;
    color: #5d5c69;
    font-size: 16px;
    margin-top: 2vh;
    width: 60%;
  }
  
  /*.........Left Text...............*/
  
  .l-box {
    left: 12vw;
    position: absolute;
    height: 100%;
    width: 20%;
    text-align: left;
    top: -4vh;
    z-index: 999;
    font-family: "Poppins", sans-serif;
  }
  
 
  
  .s-down {
    position: absolute;
    text-align: right;
    top: 50%;
    left: -3.5vw;
    width: 100%;
    line-height: 1;
    z-index: 5;
    opacity: 1;
    visibility: visible;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
   
  }
  .s-down a {
    font-family: "Poppins", sans-serif;
    color: #f4f4f4 !important;
    font-size: 0.8rem;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    font-weight: 100;
    line-height: 1.4rem;
    text-decoration: none;
    display: block;
    position: relative;
    padding-bottom: 0.8rem;
    overflow: hidden;
  }
  
 
  .s-down a:before {
    position: absolute;
    top: 55%;
    right: 11.5vw;
    width: 40%;
    height: 1px;
    background: #f4f4f4;
    content: " ";
   
  }
  
 
  
  
  /*.......btn..........*/
  
  .button-block {
    position: absolute;
    left: 0;
    bottom: -2.5rem;
    z-index: 7;
    transition: all 0.25s ease-out;
  }
  .button-block {
    display: inline-block;
    padding: 1em 1.3em 1em;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.1em;
    transition: cubic-bezier(0.22, 0.61, 0.36, 1);
    text-decoration: none;
  }
  .button-block--brand {
    border: 2px solid #5d5c69;
    color: #5d5c69;
    box-shadow: 2px 2px 0;
  }
  .button-block--brand:hover {
    border: 2px solid #e54b4b;
    color: #e54b4b;
    box-shadow: -2px -2px 0;
    -webkit-transition: color 0.15s ease-out;
    -moz-transition: color 0.15s ease-out;
    transition: color 0.15s ease-out;
    transition: all 0.4s;
    text-decoration: none !important;
  }
  .button-block--brand:hover:after {
    content: "";
    position: absolute;
    left: 14%;
    bottom: 13px;
    width: 80%;
    background: url(https://promo-theme.com/rolly/wp-content/themes/rolly/images/nav-hover.png)
      50% repeat;
    background-size: 12px auto;
    height: 12px;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    transition: all 0.5s ease;
    z-index: -1;
  }
  