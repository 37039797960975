
[data-theme="Career__dark"] {
    --color-bg: hsl(232, 11%, 15%);
    --color-bg-light: hsl(233, 8%, 19%);
  }
  
  .Career__box {
                /* 1. inner glow - for dark mode 👇 */
    box-shadow: inset 0 0 0.5px 1px hsla(0, 0%, 100%, 0.1),
                /* 2. shadow ring 👇 */
                0 0 0 1px hsla(230, 13%, 9%, 0.075),
                /* 3. multiple soft shadows 👇 */
                0 0.3px 0.4px hsla(230, 13%, 9%, 0.02),
                0 0.9px 1.5px hsla(230, 13%, 9%, 0.045),
                0 3.5px 6px hsla(230, 13%, 9%, 0.09);
    
    width: 100%;
    height: 100%;
    max-width: 50%;
    max-height: 50%;
    aspect-ratio: 1/1;
    background-color: var(--color-bg-light);
    border-radius: 12px;
    padding: 35px;
  }


  
  .Career__light{
    background-color: #e54b4b !important;
  }


  
  /* demo stuff */
  .Career__body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    position: relative;
    /* margin-top: 100px; */
  }
  
  .Career__section {
    background-color: var(--color-bg);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
  }

  .Career__text{
    position: absolute;
    top: 0;
    left:20px;
    text-align: center;  
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    width: 200px;

}

  .Career__text h1,h3{
    color: #e54b4b
  }

  .Career__box p{
    color: #000
  }

  .Career__text h1{
    font-size: 40px;
  }
  
  .Career__box__text > p{
    color:#e54b4b 
  }
  .Career__box__text > h2{
    color:#e54b4b
  }

  .Career__light .Career__box > p{
    /* color:#22232a  */
    color:#fff 
  }
  .Career__light .Career__box > h2{
    /* color:#22232a */
    color:#fff
  }



    @media (max-width: 930px) {
        .Career__box {
            max-width: 70%;
             max-height: 70%;
        }
    }

    @media (max-width: 680px) {
        .Career__text{
            left: 35%
        }
        .Career__box{
            margin-top: 80px;
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }

  @media (min-width: 42.5rem) {
    .Career__body {
      flex-direction: row;
    }
    
    .Career__section {
      width: 50%;
    }
  }