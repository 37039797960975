
.Vision__body {
    font-family: 'Poppins';
    padding:3rem 3rem 4rem 3rem;
    /* background:#000022 url(https://images.unsplash.com/photo-1533134486753-c833f0ed4866?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80) no-repeat center center; */
    background-size:cover;
    background:#22232a ;
padding-top: 105px;
    min-height: 100vh;
  }
  .Vision__container {
    display:flex;
    flex-flow: row wrap;
    max-width:1600px;
    margin:auto;
  }
  .Vision__col {
    margin:auto;
  }
  .Vision__left {
    flex:1 100%;
    order:2;
    /* background:rgba(0,0,0,.3); */
    background-color: #e54b4b;
    padding:3rem;
    border-radius:2rem 2rem 0 0;
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    overflow:none;
    color:white;
    display:flex;
    flex-flow: column wrap;
    justify-content:space-between;
  }
  .Vision__right {
    order:3;
    flex:1 100%;
    background:#eee;
    padding:3rem;
    border-radius:0 0 2rem 2rem;
    font-size:20px;
    /* is there a better way to position the scrollbar? */
    border-right:40px solid transparent;
    border-top:40px solid transparent;
    border-bottom:40px solid transparent;
  }
  .Vision__left > div {
    order:2;
  }
  .Vision__left h1 {
    font-size:70px;
    margin:3rem 0 0;
    line-height:1;
    color: #fff

  }

  .Vision__left p {
color: #d3caca
  }
.Vision__right h2{
    color: #e54b4b
}

.Vision__right p span{
    color: #e54b4b;
    margin-left: 4px;
    font-weight: 600px;
}

  .back {
    padding:1rem 2rem;
    border:2px solid white;
    border-radius:100px;
    display:inline;
    width:max-content;
    color:white;
    text-decoration:none;
    font-weight:bold;
    order:1;
  }
  .back:hover {
    background:white;
    color:black;
  }
  .Vision__right::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius:5px;
    background-color: #ccc;
  }
  .Vision__right::-webkit-scrollbar-thumb {
    background: #777;
    border-radius:5px;
  }
  
  /* on desktop header at bottom, on mobile up at top */
  .Vision__header {
    order:1;
    flex:1 100%;
    color:white;
    padding:0 0 4rem 0;
    display:flex;
    justify-content: space-between;
  }
  .site-title {
    margin:auto 0;
    font-size:24px;
    font-weight:bold;
  }
  .profile {
    height:50px;
    width:50px;
    border-radius:100%;
    background:white;
    margin:auto 0;
  }
  
  @media all and (min-width: 1200px) {
    body {
      height:100vh;
    }
    .Vision__left {
      border-radius:2rem 0 0 2rem;
      order:1;
      flex: 1 0;
    }
    .Vision__left > div {
      order:1;
    }
    .back {
      order:2;
    }
    .Vision__right {
      border-radius:0 2rem 2rem 0;
      flex:2 0px;
      order:2;
    }
    .Vision__col {
      margin:auto;
      max-height:calc(100vh - 14rem);
      height:100vh;
      overflow-y:scroll;
    }
    .Vision__header {
      padding:4rem 0 0;
      order:3;
    }
  }

  @media (max-width: 768px) {
    .Vision__right {
      /* order:3;
      flex:1 100%;
      background:#eee; */
      padding: 0rem 0rem 1rem 2rem;
      /* border-radius:0 0 2rem 2rem;
      font-size:20px;
      border-right:40px solid transparent;
      border-top:40px solid transparent;
      border-bottom:40px solid transparent; */
    }
  }