.myPadding{
  padding: 40px;
  max-width: 600px;
  margin: 0 auto;
}
.myBtnBg{
  background-color: #e54b4b !important;
  color: #fff;
}


  .Contact__bg{

    background-image: url('../pic//contactbg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;


  }

/* button {
  border: none;
  outline: none;
  background-color: none;
} */

.myBtnBg {
  padding: 18px 25px;
  border-radius: 6px;
  transition: 0.3s all ease;
  cursor: pointer;
  color: #000;
  transition: 0.5s;

}

.myBtnBg {
  background: #e54b4b;
  color: white;
  transform: translateY(-10px);
}
.myBtnBg:hover {
  background: linear-gradient(145deg, #e2e8ec, #ffffff);
  box-shadow: 4px 4px 8px #cbcbcb, -4px -4px 8px #ffffff;
  border-radius: 10px;
  color:#000
}

.contact{
  width: 500px;
}


.Contact .left {
  width: 36%;
}
.Contact .right {
  width: 60%;
}
.Contact .box {
  padding: 30px;
}
.Contact .details h1 {
  margin: 20px 0 20px 0;
  font-size: 35px;
}
.Contact .details p {
  font-size: 17px;
}
.Contact .button {
  margin-top: 20px;
}
.Contact button {
  margin-right: 20px;
}

.Contact .right {
  padding: 35px;
}
.Contact .input span {
  font-size: 13px;
}
/* .Contact input {
  margin-top: 10px;
} */
.Contact .right button {
  width: 100%;
  color: #e54b4b;
}
.Contact .right button:hover {
  color: white;
}
.Contact .right button i {
  margin-left: 10px;
}
@media (max-width: 768px) {
  .Contact .d_flex {
    flex-direction: column;
  }
  .Contact .left,
  .Contact .right {
    width: 100%;
    margin-top: 50px;
  }
}
