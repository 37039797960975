.footer-mp  .col_1 h4{
    color: #ff014c
}

.centers{
  align-items: flex-start;
  justify-content: start;
  gap: 10px;
}



.centers p{
  margin-bottom: 28px;
}

.footer-mp{
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 35px;
      place-items: center
}

.footer-mp h4 {
    font-weight: 500;
    letter-spacing: 2px;
    color: #43474b;
    font-size: 15px;
    margin: 30px 0 30px 0;
  }
  .footer-mp button {
    margin-right: 30px;
  }
  .footer-mp i {
    font-size: 20px;
  }
  
  .footer-mp button img {
    width: 20px;
    height: 20px;
  }

  .button .ml{
    margin-left: 25px;
    /* background-color: #ff014c; */
    color:#e54b4b
  }


  @media (max-width: 768px) {
    /* .grid {
      grid-template-columns: repeat(2, 1fr);
    }
    .left,
    .right {
      width: 100%;
    } */
  }

  /* Scrollbar */

.Footer__last__item{
  padding: 20px;
}
