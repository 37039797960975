@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
body {
  font-family: "Poppins", sans-serif;
  background-color: #fafafa;
}
.container {
  max-width: 90%;
  margin: auto;
}
.d_flex {
  display: flex;
  justify-content: space-between;

}
.f_flex {
  display: flex;
}
a {
  text-decoration: none;
  color: black;
}
li {
  list-style: none;
}
.uppercase {
  text-transform: uppercase;
}
.capitalize {
  text-transform: capitalize;
}

button {
  border: none;
  outline: none;
  background-color: none;
}

.btn_shadow {
  padding: 18px 25px;
  border-radius: 6px;
  transition: 0.3s all ease;
  cursor: pointer;
  color: #000;
  transition: 0.5s;
}
.btn_shadow {
  box-shadow: 4px 4px 8px #cbcbcb, -4px -4px 8px #ffffff;
}
.btn_shadow:hover {
  background: #e54b4b;
  color: white;
  transform: translateY(-10px);
}
.box_shodow {
  background: linear-gradient(145deg, #e2e8ec, #ffffff);
  box-shadow: 4px 4px 8px #cbcbcb, -4px -4px 8px #ffffff;
  border-radius: 10px;
}
.row,
.left,
.right {
  width: 50%;
}
.right{
  border: 50%;
}
.top {
  margin-top: 80px;
}
.mtop {
  margin-top: 40px;
}
.heading {
  margin: 50px 0 50px 0;
}
.heading h4 {
  color: #e54b4b;
  letter-spacing: 2px;
  font-weight: 400;
}
.heading h1 {
  font-size: 40px;
  color: #3c3e41;
}
h1 {
  color: #3c3e41;
}
.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 35px;
  place-items: center
}
p {
  color: #3c3e41;
  letter-spacing: 1px;
  font-weight: 400;
  font-size: 14px;
}
.text-center {
  text-align: center;
}
.primary_color {
  color: #e54b4b;
}
textarea,
input {
   width: 100%;

  margin-bottom: 20px; 
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  outline: none;
  transition: border-color 0.2s ease-in-out;
}

textarea:focus,
input:focus {
  border-color: #e54b4b;
}
/*----------Footer---------*/
footer {
  margin: 20% 0 10% 0;
}
footer p {
  /* margin-top: 30px; */
}
/*----------Footer---------*/


.Vision__button {
  height: 130px;
  width: 130px;
  position: absolute;
  top: 290px;
  left: 35%;
  background: #003342;
  border-radius: 150px;
  z-index: 2;
}

.Vision__button span {
  width: 100%;
  text-align: center;
  color: white;
  position: absolute;
  top: 20px;
  font-size: 48px;
}

.Vision__button:hover {
  cursor: pointer;
  background: #004472;
}

.Vision__button:hover  span{
  transform: rotate(-45deg);
  transition: 0.5s;
}



@media only screen and (max-width: 1000px) {
  .Vision__button {
      height: 130px;
      width: 130px;
      position: absolute;
      top: 290px;
      left: 32%;
      background: #003342;
      border-radius: 150px;
      z-index: 2;
  }
}

@media only screen and (max-width: 876px) {
  .Vision__button {
      height: 130px;
      width: 130px;
      position: absolute;
      top: 290px;
      left: 28%;
      background: #003342;
      border-radius: 150px;
      z-index: 2;
  }
  .in-b-txt {
      margin-bottom: 6vh;
      font-weight: 400;
      color: #5d5c69;
      font-size: 16px;
      margin-top: 2vh;
      margin-left: 40px;
    }
}

@media only screen and (max-width: 768px) {
  .Vision__button {
      height: 60px;
      width: 60px;
      position: absolute;
      top: 85%;
      left: 70%;
      background: #003342;
      border-radius: 150px;
      z-index: 29;
  }
  .in-b-txt {
      margin-bottom: 6vh;
      font-weight: 400;
      color: #5d5c69;
      font-size: 16px;
      margin-top: 2vh;
      margin-left: 0;
    }
    .Vision__button span {
      width: 100%;
      text-align: center;
      color: white;
      position: absolute;
      top: 20px;
      font-size: 17px;
  }
  .Vision__button:hover  span{
    transform: rotate(110deg);
    transition: 0.5s;
  }
}
@media (max-width: 768px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .left,
  .right {
    width: 100%;
  }
  .d_flex{
  flex-direction: column;

  }
  
  .left{
    order:11
  }

  .right{
    order: 1
  }
  
}

@media (max-width: 480px) {
  .grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .left,
  .right {
    width: 100%;
  }
  .container{
    overflow-x: hidden;
  }
}

/* scroll style */

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track styles */
::-webkit-scrollbar-track {
  background: #888;
}

/* Handle styles */
::-webkit-scrollbar-thumb {
  background: #f1f1f1;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
